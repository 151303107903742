<template>
  <div class="pan-box">
    <div class="nav-title">
      <i></i>首页—  工作总览
    </div>
    <div class="content">
      <div class="title">
        <span class="active">工作总览</span>
      </div>
      <div class="list">
        <div class="top">
          <div class="left">
            <div class="sort" @click="handleCreation">
              <span>创建时间</span>
              <img src="../../assets/icon-nosort.png" v-if="this.sortType == 'handleTime'" alt="" />
              <div v-else>
                <img src="../../assets/paixu1.png" v-if="createTime" alt="" />
                <img src="../../assets/paixu2.png" v-if="!createTime" alt="" />
              </div>
            </div>
            <div class="sort" @click="handleOperation">
              <span>操作时间</span>
              <img src="../../assets/icon-nosort.png" v-if="this.sortType == 'createTime'" alt="" />
              <div v-else>
                <img src="../../assets/paixu1.png" v-if="handleTime" alt="" />
                <img src="../../assets/paixu2.png" v-if="!handleTime" alt="" />
              </div>
            </div>
            <div class="select-box">
              <a-select
                :getPopupContainer="
                  triggerNode => {
                    return triggerNode.parentNode || document.body;
                  }
                "
                v-model="filterType"
                style="width: 190px"
                placeholder="筛选业务类型"
                :options="filterTypeList"
                @change="handleSearch"
                
              >
              </a-select>
            </div>
            <div class="select-box">
              <a-select
                :getPopupContainer="
                  triggerNode => {
                    return triggerNode.parentNode || document.body;
                  }
                "
                v-model="progressSimple"
                style="width: 190px"
                placeholder="筛选繁简版本"
                :options="progressSimpleList"
                @change="handleSearch"
              >
              </a-select>
            </div>
          </div>
          <div class="right">
            <div class="input">
              <input type="text" v-model="inputValue" placeholder="输入图书名称" @keyup.enter="handleSearch">
              <img src="../../assets/fangdajing.png" alt="" @click="handleSearch">
            </div>
            <span class="button-search" @click="handleDown">下载</span>
            <span class="button-primary" @click="handleAll">全选</span>
            <span class="button-primary" @click="handleCancleAll">取消全选</span>
            <span class="button-cancle" @click="handleDelete">删除</span>
          </div>
        </div>
        <div class="bottom">
          <a-spin :spinning="loading" tip="正在生成文档...">
            <div class="book-list">
              <List
                :list="bookList"
                @refresh="handleOcrList"
                @openUrlLoading="openUrlLoading"
                :handleCheckedBook="handleCheckedBook"
                :checkedList="checkedList"
              />
            </div>
          </a-spin>
        </div>
       
        <div class="pagination">
          <a-pagination
            v-model="pageNum"
            :total="total"
            show-size-changer
            :page-size="pageSize"
            @showSizeChange="onShowSizeChange"
            :show-total="total => `共 ${total} 条`"
            @change="handleChange"
          >
            <template slot="buildOptionText" slot-scope="props">
              <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
            </template>
          </a-pagination>
        </div>
      </div>
    </div>

    <!-- 删除提示 -->
    <HintOrDownModal
      v-if="deleteVisible"
      :visible="deleteVisible"
      :handleClose="handleDeleteClose"
      :tipsType="modalType"
      :handleCallback="handleDeleteCallBack"
      :picNum="picNum"
      :boxNum="this.checkedList.length"
      :textNum="textNum"
    />

    <!-- 下载提示 -->
    <DownModal
      v-if="downVisible"
      :visible="downVisible"
      :handleClose="handleDownClose"
      :handleCallback="handleDownCallBack"
      :bookNameList="bookNameList"
    />
  </div>
</template>

<script>
import List from '../../components/List.vue';
import { Pagination } from 'ant-design-vue';
import { getWorkList, deleteBook} from '@/api/ocr'
import HintOrDownModal from '../../components/HintOrDownModal.vue'
import { downloadByUrl } from '@/utils/download'
import DownModal from '@/components/DownModal.vue'

export default {
  components: { List, Pagination, HintOrDownModal, DownModal },
  created() {
    this.handleOcrList()
  },
  data() {
    return {
      inputValue: '',
      pageNum: 1,
      pageSize: 10,
      total: 0,
      /** 创建时间排序  */
      createTime : false,
      /** 操作时间排序*/
      handleTime: true,
      sortType: 'createTime',
      /** 图书列表 */
      bookList: [],
      filterTypeList: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 0,
          label: 'OCR'
        },
        {
          value: 1,
          label: '文本处理'
        },
        {
          value: 2,
          label: '在线编校'
        }
      ],
      filterType: undefined, // 业务类型
      /** 选中的图书 */
      checkedList: [], 
      /** HintOrDownModal visible */
      downVisible: false,
      title: '下载',
      modalType: 'tipsTwo',
      picNum: 0,
      textNum: 0,
      deleteVisible: false,
      bookNameList: [], // 选中的下载图书name列表
      loading: false,
      progressSimple: undefined,
      progressSimpleList: [
        {
          value: 2,
          label: '全部'
        },
        {
          value: 0,
          label: '繁体版'
        },
        {
          value: 1,
          label: '简体版'
        },
      ]
    };
  },
  methods: {
    openUrlLoading(data) {
      this.loading = data
    },
    /** 排序 */
    handleCreation() {
      this.sortType = "createTime"
      this.createTime = !this.createTime
      this.pageNum = 1
      this.handleOcrList()
    },
    handleOperation() {
      this.sortType = "handleTime"
      this.handleTime = !this.handleTime
      this.pageNum = 1
      this.handleOcrList()
    },
    /** 搜索 */
    handleSearch() {
      this.pageNum = 1
      this.handleOcrList()
    },
    handleCheckedBook(id, checked) {
      if (checked) {
        this.checkedList.push(id)
      } else {
        const index = this.checkedList.findIndex(item => item === id)
        this.checkedList.splice(index, 1)
      }
    },
    /** 删除 */
    async handleDelete() {
      if (this.checkedList.length) {
        this.picNum = 0
        this.textNum = 0
        this.bookList.map(item => {
          this.checkedList.map(itm => {
            if (item.id == itm) {
              this.textNum += item.textCount 
              this.picNum += item.countPage
            }
          })
        })
        this.downVisible = true
        this.title = '提示'
        this.modalType = 'tipsTwo'
      } else {
        this.$message.warning('请先选择要删除的图书');
      }
    },
    // 全选
    handleAll() {
      this.checkedList = []
      this.bookList.map(item => {
        this.checkedList.push(item.id)
      })
    },
    // 取消全选
    handleCancleAll() {
      this.checkedList = []
    },
    /** 每页显示条数改变 */
    onShowSizeChange(current, pageSize) {
      this.pageSize = pageSize;
      this.handleOcrList()
    },
    /** 翻页 */
    handleChange()  {
      this.handleOcrList()
    },
    /** 获取ocr图书列表 */
    async handleOcrList() {
      this.checkedList = []
      const r = await getWorkList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        filterType: this.filterType,
        bookName: this.inputValue,
        order: this.sortType,
        sort: this[this.sortType] ? 'asc' : 'desc',
        progressSimple: this.progressSimple
      })
      this.bookList = r.rows || []
      this.total = r.total
    },
    /** 下载 */
    handleDown() {
      if (this.checkedList.length) {
        let str = ''
        this.bookList.map(item => {
          this.checkedList.map(itm => {
            if (itm == item.id) {
              str = str + '《'  + item.bookName + "》"
            }
          })
        })
       
        this.bookNameList = str
        this.downVisible = true
      } else {
        this.$message.warning('请先选择要下载的图书');
      }
    },
    /** 下载成功回调 */
    async handleDownCallBack(value) {
      downloadByUrl({
        requestUrl: `ocr/befDownload/${this.checkedList.join(',')}/${value}`,
        url: `ocr/download/${this.checkedList.join(',')}/${value}`,
        target: '_self',
      })
      this.downVisible = false
      this.checkedList = []
    },
    /** 关闭下载弹窗 */
    handleDownClose() {
      this.downVisible = false
    },
    /** 删除回调 */
    async handleDeleteCallBack (value) {
      try {
        const res = await deleteBook({ids: this.checkedList})
        this.$message.success(res.data);
        this.handleOcrList();
        this.checkedList = [];
        this.deleteVisible = false
      } catch (error) {
        this.handleOcrList();
        this.checkedList = [];
        this.deleteVisible = false
        console.log('error', error.msg)
      }
    },
    /** 删除 */
    async handleDelete() {
      if (!this.checkedList.length) {
        this.$message.warning('请先选择要删除的图书');
      } else {
        this.picNum = 0
        this.textNum = 0
        this.bookList.map(item => {
          this.checkedList.map(itm => {
            if (item.id == itm) {
              this.textNum += item.textCount 
              this.picNum += item.countPage
            }
          })
        })
        this.deleteVisible = true
      }
    },
    /** 关闭删除弹窗 */
    handleDeleteClose() {
      this.deleteVisible = false
    },
  },
};
</script>

<style lang="less">
.pan-box {
  width: 100%;
  background: #edf0f2;
  padding: 46px 0px 100px;
  box-sizing: border-box;

  .content {
    width: auto;
    padding: 0px 51px;

    .list {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      border: 1px solid #e6e6e6;
      margin-top: 10px;
      padding: 0px 26px 16px 26px;
      box-sizing: border-box;

      .top {
        width: 100%;
        height: 59px;
        border-bottom: 1px dashed #c6c4c4;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          // flex: 500px 0 0;
          height: 20px;
          display: flex;
          align-items: center;
        }

        .right {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }

      .bottom {
        width: 100%;
        min-height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        flex-wrap: wrap;

        &-content {
          width: 100%;
          display: flex;
          // justify-content: space-evenly;
          flex-wrap: wrap;
        }

        .book-list {
          width: 100%;
          min-height: 300px;
        }
      }
    }
  }

  .select-box {
    position: relative;
  }

  .ant-select-dropdown {
    z-index: 9;
  }
  .ant-spin-nested-loading {
    width: 100%;
  }

  .select-box {
    margin-left: 10px;
  }
}

</style>
